.App {
  text-align: center;
  background-color: #EFF3FB;
}
/* Frame styles */

.Frame {
  padding-left: 48px;
  margin-left: 48px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 48px;
  margin-right: 48px;
  text-align: left;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.App .Frame:nth-child(2) {
  margin-top: 240px;
}

.App-Mobile .Frame:nth-child(2) {
  margin-top: 80px;
}
.Frame {
  border-bottom: 1px solid #6D91D9;
}

.Frame h2 {
  color: #000052;
  margin-top: 0px;
}

@keyframes header-shrink {
  from { height: 240px; }
  50% { height: 240px; }
  to { height: 80px; }
}

@keyframes header-grow {
  from { height: 80px; }
  50% { height: 240px; }
  to { height: 240px; }
}
.Header {
  height: 240px;
  background-color: #93AEE3;
  padding-left: 0px;
  display: flex;
  justify-content: space-between;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
}

.Header.Dirty {
  animation: header-grow 0.6s;
}

.Header.Small {
  height: 80px;
  animation: header-shrink 0.6s;
}
.App-Mobile .Header.Small {
  height: 80px;
  animation: none;
}


@keyframes headshot-shrink {
  from { height: 192px; width: 192px; }
  50% { height: 192px; width: 192px; }
  to { height: 60px; width: 60px; }
}

@keyframes headshot-grow {
  from { height: 60px; width: 60px; }
  50% { height: 192px; width: 192px; }
  to { height: 192px; width: 192px; }
}
.Header .Headshot {
  height: 100%;
  width: 100%;
}
.Header .Headshot-container {
  height: 192px;
  width: 192px;
  border-radius: 50%;
  overflow: hidden;
  align-self: center;
  margin-left: 48px;
}
.Header.Dirty .Headshot-container {
  animation: headshot-grow 0.6s;
}
.Header.Small .Headshot-container {
  height: 60px;
  width: 60px;
  animation: headshot-shrink 0.6s;
}

@keyframes text-shrink {
  from { height: 150px; }
  50% { height: 150px; }
  to { height: 80px; }
}

@keyframes text-grow {
  from { height: 80px; }
  50% { height: 80px; }
  to { height: 150px; }
}

.Header .Text-container {
  text-align: left;
  padding-left: 16px;
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 150px;
  color: #000052;
}

.Header.Dirty .Text-container{
  animation: text-grow 0.6s;
}

.Header.Small .Text-container{
  height: 80px;
  animation: text-shrink 0.6s;
}
.Header .Text-container .Primary {
  font-weight: bold;
  font-size: 32px;
  flex: 1;
}
.Header .Text-container .Secondary {  
  font-weight: 500;
  font-size: 20px;
  flex: 1;
}

@keyframes fade-out-shrink {
  from { font-size: 16px; opacity: 1; }
  50% { font-size: 16px; opacity: 0; }
  to { font-size: 0px; opacity: 0; }
}
@keyframes fade-in-grow {
  from { font-size: 0px; opacity: 0; }
  50% { font-size: 16px; opacity: 0; }
  to { font-size: 16px; opacity: 1; }
}

.Header .Text-container .Tertiary {
  opacity: 1;
  font-size: 16px;
}
.Header.Dirty .Text-container .Tertiary {
  animation: fade-in-grow 0.6s;
}
.Header.Small .Text-container .Tertiary {
  opacity: 0;
  font-size: 0px;
  animation: fade-out-shrink 0.6s;
}

.Header .Menu-container .hamburger {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 9999;
}

@keyframes drawer-shrink {
  from { width: 320px }
  50% { width: 320px }
  to { width: 160px }
}
@keyframes drawer-grow {
  from { width: 160px }
  50% { width: 320px }
  to { width: 320px }
}

@keyframes slide-out {
  from {
    transform: translate(0%, 0%);
  }
  to {
    transform: translate(102%, 0%);
  }
}

@keyframes slide-in {
  from {
    transform: translate(102%, 0%);
  }
  to {
    transform: translate(0%, 0%);
  }
}
.Header .Menu-container .Background {
  height: 160px; /*tall-header height*/
  width: 320px;
  z-index: 99;
}
.Header.Dirty .Menu-container .Background{
  animation-name: drawer-grow;
  animation-duration: 0.6s;
}
.Header.Small .Menu-container .Background{
  animation-name: drawer-shrink;
  animation-duration: 0.6s;
  width: 160px;
}
.Header.Mobile .Menu-container .Background{
  width: 160px;
  animation: none !important;
}


.Header .Menu-container .Background .Background-inner{
  animation-duration: 0.3s;
  transform: translate(102%, 0%);
  height:100%;
  width:100%;
  padding-top: 80px; /*short-header height*/
  background-color: #93AEE3;
}

.Header .Menu-container .Background.Dirty .Background-inner{
  animation-name: slide-out;
  animation-duration: 0.3s;
}
.Header .Menu-container .Background.Visible .Background-inner{
  animation-name: slide-in;
  animation-duration: 0.3s;
  transform: translate(0%, 0%);
}

/* usable width: 240 */
/* usable height:  180*/
.Header .Menu-container .Background .Navigation-link{
  display: block;
  width: 120px;
  height: 28px;
  border: none;
  background: none;
  text-align: left;
  font-weight: 600;
  font-size: large;
}

.Header .Menu-container .Background .Navigation-link:hover{
  color: #000052;
}
.Header .Menu-container .Background.Visible .Navigation-link{
  cursor: pointer;
}

/* @keyframes odd-stagger {
  from {
    transform: translate(20px, 50%);
  }
  50% {
    transform: translate(20px, 50%);
  }
  to {
    transform: translate(20px, 50%);
  }
}
@keyframes odd-unstagger {
  from {
    transform: translate(20px, 50%);
  }
  50% {
    transform: translate(20px, 50%);
  }
  to {
    transform: translate(20px, 50%);
  }
} */

@keyframes even-stagger {
  from {
    transform: translate(20px, 0%);
  }
  50% {
    transform: translate(160px, 0%);
  }
  to {
    transform: translate(160px, -100%);
  }
}
@keyframes even-unstagger {
  from {
    transform: translate(160px, -100%);
  }
  50% {
    transform: translate(160px, 0%);
  }
  to {
    transform: translate(20px, 0%);
  }
}

.Header .Menu-container .Background .Navigation-link:nth-child(odd){
  transform: translate(20px, 0%);
}
.Header .Menu-container .Background .Navigation-link:nth-child(even){
  transform: translate(160px, -100%);
}

/* .Header.Dirty .Menu-container .Background .Navigation-link:nth-child(odd){
  animation-name: odd-stagger;
  animation-duration: 0.6s;
} */
.Header.Dirty .Menu-container .Background .Navigation-link:nth-child(even){
  animation-name: even-stagger;
  animation-duration: 0.6s;
  transform: translate(160px, -100%);
}


/* .Header.Small .Menu-container .Background .Navigation-link:nth-child(odd){
  animation-name: odd-unstagger;
  animation-duration: 0.6s;
  transform: translate(20px, 0%);
} */
.Header.Small .Menu-container .Background .Navigation-link:nth-child(even){
  animation-name: even-unstagger;
  animation-duration: 0.6s;
  transform: translate(20px, 0%);
}

.Mobile-Header {
  border: 1px solid red;
  overflow-x: clip;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
}

.Mobile-Header .Top-bar {
  border: 1px solid blue;
  display: flex;
  flex-direction: row;
}

.Mobile-Header .Text-container {
  text-align: left;
  padding-left: 16px;
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  color: #000052;
}
.BioFrame .Text-container {
  text-align: left;
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  color: #000052;
}

.BioFrame .Text-container .Secondary {
  font-size: 18px;
  font-weight: bold;
  flex: 1;
}

.BioFrame .Text-container .Tertiary {
  font-size: 14px;
  flex: 1;
}

.Mobile-Header .Text-container .Primary {
  font-weight: bold;
  font-size: 24px;
  flex: 1;
}

.Mobile-Header .Text-container .Secondary {
  font-size: 14px;
  flex: 1;
}

.Mobile-Header .Links-container {
  background-color: yellow;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-evenly;
}

.Mobile-Header .Navigation-link {
  display: block;
  border: none;
  background: none;
  text-align: left;
  font-weight: 600;
  font-size: large;
}

.Mobile-Header .Headshot-container {
  width: 90vw;
  height: 90vw;
  border-radius: 45vw;
  overflow: hidden;
  align-self: center;
}

.Mobile-Header .Headshot {
  width: 100%;
  height: 100%;
  object-fit: cover;
}